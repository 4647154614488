<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>Wallet Groups</h4>
              </CCol>
              <CCol md="4">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
                   
              </CCol>
              <CCol md="3">                   
                   <b-button variant="primary" to="/wallet/groups" >New Group</b-button>
              </CCol>
          </CRow>
        </CCardHeader><br/>
        <CCardBody>       
          <div class="table-responsive table">
            <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">

              <template v-slot:cell(createDate)="{ item }"> 
                    {{ item.createDate | dateSubstr }}
              </template>

               <template v-slot:cell(actions)="{ item }">                
                  <b-button variant="info" :to='"/wallet/detail/" + item.groupUniqueNumber' >Detail</b-button>
              </template>

            </b-table>
          </div>
          <div>
            <b-row>
              <b-col sm="3">
                <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
              </b-col>       
              <b-col sm="9">
                <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                </b-form-select>
              </b-col>
            </b-row>      
          </div>          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AuthService from '@/api/AuthService.js';

 export default {
    name: "walletgroups",
    data() {
      return {
        items:[],             
        fields: [   
          {
            name : 'userId',
            key: 'groupUniqueNumber',
            label : 'Wallet Group ID',
            sortable: true
          },  
          {
            key: 'name',
            label : 'Wallet Group Name',
            sortable: true
          },    
          {
            key: 'features',
            label : 'Features',
            sortable: true
          },                   
          {
            key: 'followUserGroup',
            label : 'Follow User Group',
            sortable: true
          }, 
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          },
          {            
            key: 'actions',
            label: 'Actions'         
          } 
          
          ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        }


    },

    created () { 
       this.getWalletGroupList();      
    },
    methods: {      

      getWalletGroupList: function() {
        AuthService.getWalletGroupList().then(response => {         
        this.items = response;  //console.log(response);
        }, error => {
          this.loading = false;
        });
      }
    },
    mounted() { 
       //console.log(process.env.VUE_APP_ROOT_API);
    },   
 }; 

</script>
<style>
    .table{overflow-y:scroll;}
</style>